<template>
    <div> 
      <v-container>

        <v-layout wrap justify-space-around>

          <v-flex xs12>
              <h1 style="text-align: center" class="mt-5 blue-grey--text"><v-icon size="75" class="blue-grey--text text--darken-3">mdi-compass</v-icon>Reset Password @ W5th</h1>
          </v-flex>

          

          <v-flex xs12 md6 mt-3>

                  <v-alert width="100%" type="error" textt outlinedd v-if=validators class='font-weight-medium' dense >    
                    <div v-for="validator in validators" :key=validator.param>
                      {{validator.param}}: {{validator.msg}}
                    </div>

                  </v-alert>
                  <v-alert :type=flashMessage.type v-if=flashMessage.msg class='font-weight-medium' dense>        
                    <div>
                      {{flashMessage.msg}}
                    </div>
                  </v-alert>

                <v-form @submit.prevent="login">  

                  <v-text-field 
                  autocorrect="off" 
                  autocapitalize="off" 
                  spellcheck="false"                   
                  name="email"  
                  type="text" 
                  placeholder="Email" 
                  append-icon="mdi-email">
                  </v-text-field>    
                  <!-- <v-text-field name="password"  type="password" placeholder="Password" append-icon="mdi-lock"></v-text-field>     -->
                  <v-btn outlined tile class="blue-grey--text my-3" type="submit" value="Login"><span>Submit</span><v-icon small right>mdi-exit-to-app</v-icon></v-btn>               
              </v-form>

            </v-flex>                
        </v-layout>
      </v-container>      
    </div>
</template>

<script>
    // import router from 'vue-router'
    import axios from "axios"
    
    // axios.defaults.withCredentials = true;

    export default {    
        name: "Reset",  
        data(){
          return{
 
            validators: null,

          }
          
        }, 
        props:['flashMessage'],
        methods: { 
            // flashMessage: () => {
            //   // emits a message to app.vu and updates the flashmessage
            //   this.$emit('updateFlash', {type: 'success', msg: 'Please check your email to proceed to the next step'})
            // }, 
          login: function (e) { 
              
            e.preventDefault() 
            this.validators = null  
            this.$emit('updateFlash', {})
            let email = e.target.elements.email.value
            // let password = e.target.elements.password.value
            let login = () => {    
              let data = {    
                email: email,    
                  // password: password    
              }    
              //!!!axios.post("/api/login", data)    
              axios.post(process.env.VUE_APP_EX_IP + '/api/send_reset', data, {withCredentials: false})   
                  // was (response)
                  .then(() => { 
                    // register has been started, an email is sent and the app.vu flash gets updated and login page is then called
                    this.$emit('updateFlash', {type: 'success', msg: 'Please check your email to proceed to the next step'})
 
                })    
                .catch((errors) => {  
                    if (errors.response){
                        this.validators = errors.response.data.errors
                    }  else if ( errors.request){
                        this.validators = ([{msg:'Server cannot be reached', param:'Networking'}])
                    } else {
                        // nothing should ever get here
                        console.log('something very bad happend')
                    }
                })    
            }    
            login()    
          },
    
        },
        mounted(){
        },
        beforeMount(){
        },
        beforeDestroy(){
            // before it destroys the login session clear the flash message
            this.$emit('updateFlash', {})

        }            
    }
</script>