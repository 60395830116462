<template>
  <div>
    status: {{ name }}
  </div>
</template>

<script>
import axios from "axios"
export default {
  name: 'Restricted',
  data(){
    return {
      //testMethod
      name: '',
      viewCounter: 0,
      status: ''

    }
  },
  methods: {
    testMethod: function () {
      //e.preventDefault()
      //return(let a = 'hey there')
      //this.name = 'now its a new name'
      axios.get("api/restricted")
      .then((response) => {
        // console.log('entire response', response)
        // console.log('when is this called', response.data)
        this.name = response.data
      })
      .catch((errors) => {    
          console.log(errors)    
          console.log('vue response')
          this.viewCounter += 1
          console.log(this.viewCounter)
          //this.$router.push("/")    
      }) 
    }
  },
  beforeMount(){
    //this.name = 'hey there now mounted',
    this.testMethod()
  }
}
  
</script>

<style>

</style>