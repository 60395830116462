import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/components/Login'
// import Dashboard from '@/components/Dashboard'
import Restricted from '@/components/Restricted'

// removed because logout is now a button
// import Logout from '@/components/Logout'
// import Mytests from '@/components/Mytests'
import Multisearch from '@/components/MultiSearch'

import Register from '@/components/Register'
import Confirmation from '@/components/Confirmation'

import Dashboard from '@/views/Dashboard.vue'
import About from '@/views/About.vue'
import Projects from '@/views/Projects.vue'
// import Bookmarked from '@/components/BookmarkedMain.vue'

// import Mapp from '@/components/Mapp.vue'
import Reset from '@/components/Reset.vue'

// import NewPassword from '@/components/NewPassword.vue'
import ResetSend from '@/components/ResetSend.vue'


//lazy loading
//const About = r => require.ensure([], () => r(require('@/views/About.vue')))


Vue.use(Router)


//const router = new VueRouter({
//  routes // short for `routes: routes`
//})


// history mode removed # marks in browser
export default new Router({
  mode: 'history', 
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/reset_send/:token/:email",
      name: "ResetSend",
      component: ResetSend
    },
    {
      path: "/bookmarked",
      name: "Bookmarked", 
      // component: Bookmarked,
      component: () => import("../components/BookmarkedMain.vue")
    },
    {
      path: "/reset",
      name: "Reset",
      component: Reset
    },
    { 
      path: '/about', 
      component: About, 
      meta:{title: 'new title'} 
    }, //lazy loading    
    // { path: '/dashboard2', component: Dashboard2 },    
    // {
    //   path: "/homee",
    //   component: "Homee"
    // },  
    {
      path: "/register",
      name: "Register",
      component: Register
    },
    {
      path: "/confirmation/:id",
      name: "Confirmation",
      component: Confirmation
    },
    {
      path: "/",
      name: "Login",
      alias: '/login',
      component: Login
    },
    // {
    //   path: "/logout",
    //   name: "Logout",
    //   component: Logout
    // },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard
    },
    {
      path: "/projects",
      name: "Projects",
      component: Projects
    },
    {
      path: "/multiSearch",
      name: "Multisearch",
      component: Multisearch
    },
    {
      path: "/restricted",
      name: "Restricted",
      component: Restricted
    },

    {
      path: "/mapp",
      name: "Mapp",
      component:  () => import("../components/Mapp.vue"),
      props: true //allows us to attach params to the url
    },

    // component: () => import("../components/BookmarkedMain.vue")

    // {
    //   path: '/about',
    //   name: 'about',
    //   component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
    // },

  ]
})
