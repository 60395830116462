<template>
  <v-app>  
    <Navbar />

    <v-main>  

        <router-view :flashMessage="flashMessage" v-on:updateFlash="updateFlash($event)"></router-view>
      
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  name: 'App',

  components: {
 //   HelloWorld,
    Navbar,
  },
  data: () => ({

    // flash message gets updated from login, register etc then passes 
    // those props down using the router-view
    flashMessage: ({})
  }),
  methods:{
    updateFlash: function(data){
      // console.log(data)
      this.flashMessage = data
    },
  },
};
</script>

