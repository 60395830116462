<template style="overscroll-behavior: none">
  <!-- ADDING PWA NOW -->
  <!-- <div class="multisearch"> -->
    <!-- changed to true -->
  <div > 
    
    <v-container :fluid="true" style="overscroll-behavior: none">  
      <h1 class="subtitle-1 blue-grey--text">UWI Search 

      <v-icon
      fabb
      color="blue lighten-2"
      darkk
      smalll
      v-on:click='openVideo'
      class="blue-grey--text text--darken-1"
      >
      mdi-help-circle-outline</v-icon> 
      <br> (ex. 00/04-03-022-11W4/0) </h1>
      <!-- xs, sm, md, lg and xl -->
      <!-- <v-layout wrap justify-space-around> -->
      <!-- <v-layout wrap>         -->
      <v-form @submit.prevent='getWells' class="form">

<!-- BUTTONS TOP ------------------------------------->      
        <div class="form_buttons mt-1">
          <!-- WELL BUTTONS -->
          <div class="button-container ">
            <div class="pt-0 px-0 button-container-label">Wells</div>
            <div class="pt-4 px-2 ">          
              <v-btn type="submit" :loading=searchLoading small outlinedd tile block  class="my-1 white--text" color="blue-grey darken-1">SEARCH <v-icon small>mdi-magnify</v-icon></v-btn>
              <v-btn @click='pinDrops()' small tile outlinedd block class="my-1 white--text"  color="blue-grey darken-1" v-if="getMultipleUWIResults.length">Map<v-icon small>mdi-map</v-icon></v-btn>
              <v-btn  
                small            
                block
                tile 
                outlinedd 
                :loading = showAllLoading
                v-if=noneOperatedWellCount
                v-on:click=viewNoneOperatedWells 
                class="my-1 white--text" 
                color="blue-grey darken-1" >

                  {{ viewNoneOperatedWellsFlag ? 'Hide' : 'Show All' }}
                <v-badge icon="mdi-minus-circle" color="white" class="ml-0" inline>
                  <span slot="badge" class="blue-grey--text"> {{noneOperatedWellCount}}</span>             
                </v-badge>
                <!-- <v-icon>mdi-eye</v-icon> -->
              </v-btn>
            </div>          
          </div>

          <!-- FAVORITES buttons-->
          <div class="button-container mt-4">
            <div class="pt-0 px-0 button-container-label">FAVORITES</div>
            <div class="pt-4 px-2 ">
              <v-btn  small tile outlinedd block class="my-1 white--text" title="Add filtered item to favorites"  color="blue-grey darken-1"  v-if="true" v-on:click="saveList"><span class="global-btnn">Add</span><v-icon small>mdi-folder-move</v-icon></v-btn>
              <v-btn  small tile outlinedd block class="my-1 white--text" title="Ovewrite favorites with filtered items" color="blue-grey darken-1" v-if="$store.state.saveList.length" v-on:click="saveList('overwrite')"><span class="global-btnn">Overwrite</span><v-icon small>mdi-folder-alert</v-icon></v-btn>
            </div>
          </div>  
        </div>

<!-- BUTTONS BOTTOM -------------------------------------------->





        <v-textarea 
          placeholderr="ex. 0/04-03-022-11W4"
          class="text_area mt-1 pa-0" 
          type="text" 
          color="blue-grey" 
          background-color="blue-grey lighten-4" 
          rows="10" 
          clearable 
          outlined 
          filled 
          flat
          auto-grow 
          :value='search'
          name="fname" />
  
      </v-form>
       
        <!-- this renders the list once it gets populated by 
        passing props down to it -->
        <!-- we are going to need to emmit events backup from 
        the default view so simple list gets updated -->      
        <!-- <MultiSearchListDefault v-if="this.swapView" :wells="wells" /> -->
    
      <div>
        <div v-if="getMultipleUWIResults">
            <!-- USING AN ARRAY OF ARRAYS -->
          <v-expansion-panels class="py-2" 
          accordion 
          multiple 
          v-for="(outerResult, outer_index) in getMultipleUWIResults" :key="outer_index">
            <v-layout>
              <v-flex wrap sm12>
                <v-card outlinedd class="my-1">
                  <v-toolbar class="clip_board" densee flat color="blue-grey lighten-3" >   
                    <h5 class="blue-grey--text text--darken-2 mr-2" ><span class="global-btnn">Search String</span></h5>
                    
                    <caption class="blue-grey--text text--darken-1 caption">"{{outerResult.searchstring}}"</caption>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </v-card>
              </v-flex>
            </v-layout>

            <v-expansion-panel  v-for="(innerResult, inner_index) in outerResult.foundarray" :key="inner_index" >

              <!-- THIS SHOWS ALL WELLS THAT HAVE AN OPERATOR -->
              <div v-if="innerResult.operator || !innerResult.operator && viewNoneOperatedWellsFlag" v-bind:class="{'info--text text--darken-0': !innerResult.operator}">
                <v-layout wrap  justify-end class="px-3 py-2" :hidden=true >
                  <v-flex xs8 sm4 md4 class="py-1">
                    <div class="caption grey--text">UWI</div>
                  </v-flex>
                  <v-flex xs6 sm4 md4 class="hidden-xs-only">  
                    <div class="caption grey--text" >LSD</div>  
                  </v-flex>
                  <v-flex xs4 sm4 md4>
                    <div caption class="caption grey--text mx-7">Filter</div>  
                  </v-flex>
                            
                  <v-flex xs8 sm4 md4 >
                    <div class="body-2" >{{innerResult.uwi}}</div>
                  </v-flex>
                  <v-flex xs6 sm4 md4 class="hidden-xs-only">
                    <div class="body-2">{{innerResult.surfloc}}</div>
                  </v-flex>
                  <v-flex xs4 sm4 md4 class="mt-n1">
                    <!-- <v-btn title="Removes a single item" x-small text icon color="info lighten-1"@click.native.stop  @click='removeOne(innerResult.id,outer_index)'><v-icon size="20">mdi-minus-circle</v-icon></v-btn> -->
                    <v-btn title="Removes a single item" class='mx-4' x-small text icon color="info lighten-1" @click.native.stop  @click='removeOne(inner_index,outer_index)'><v-icon size="24">mdi-minus-circle</v-icon></v-btn>
                    <v-btn title="Keep only this item" x-small text icon color="red" @click.native.stop v-on:click='keepOne(innerResult.id, outer_index)'><v-icon size="24">mdi-plus-circle</v-icon></v-btn>
                    <!-- !!! now working yet -->
                    <!-- <v-btn class="ptt-4" title="Complete Item" x-small text icon color="green" @click.native.stop v-on:click="modifyItemToDefaultProject('true', outer_index, inner_index, 'complete')"><v-icon size="20">mdi-check-circle</v-icon></v-btn> -->
                  </v-flex>
                </v-layout> 
              </div>

            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>

    </v-container>

  </div>
</template>

<script>
import axios from 'axios'

// import MultiSearchList from "@/components/MultiSearchList"
// import MultiSearchListDefault from "@/components/MultiSearchListDefault"


export default {
  name: "Multiform",
  components: {
    // MultiSearchList,
    // MultiSearchListDefault,
  },
  // props: 
  //   ['wells']
  // ,
  data () {
    return {
      // removeWellLoading: false,
      showAllLoading: false,
      searchLoading: false,
      search: "",
      // SWAP TO PRINTABLE VIEW
      swapView: true,
      // isLoggedIn: null,
      multiData: null, // form data that is passed to express f.name
      wells: [], //this is raw data passed from express data.data
    }
  },
  //  HANDED DOWN FROM APP.VUE
  props:['flashMessage'],

  computed: {
    noneOperatedWellCount(){
      return this.$store.state.noneOperatedWellCount
    },
    viewNoneOperatedWellsFlag(){
      return this.$store.state.viewNoneOperatedWells;
    },      

    getMultipleUWIResults(){
      // gives us access to createdefaultproject info from store
      return this.$store.state.getMultipleUWIResults
    }
  },
  methods: {
    saveList(option){
      this.$store.dispatch('saveList', option)
      this.$router.push('bookmarked')
    },
    saveProject(){
      let list = []
      this.$store.state.getMultipleUWIResults.forEach( (data) => {
        data.foundarray.forEach((data) => {
          list.push({name: name, well_id: data.id, description: data.description, type: data.type || 'Other', delete: false, complete: data.complete})

        })
      })
      // SAVE YOUR NEW PROJECT , CURRENTLY DISABLED
      axios.post(process.env.VUE_APP_EX_IP+'/api/save_project', list, {withCredentials: true}).then(() => {
 
        this.$store.state.getMultipleUWIResults = null
      }).catch(err => {
        console.log(err)
      })

    },

    // activated on adding notes or updating type of work
    modifyItemToDefaultProject(e, outerIndex, innerIndex, type){
      this.$store.dispatch('modifyItemToDefaultProject', {outerIndex, innerIndex, e, type})
    },
    // KEEPS A SINGLE WELL IN THE SEARCH ARRAY AND UPDATES LOCALSTORAGE
    keepOne: function(innerArray, outerArray){
      this.$store.dispatch('keepOneWell', {innerArray, outerArray})
      .then(() => {
        this.$store.commit('updateLocalUWISearch')
      })
    },
    // REMOVE A SINGLE WELL IN THE SEARCH ARRAY AND UPDATES LOCALSTORAGE
    removeOne: function(innerArray, outerArray){ 
      // console.log('remove one loading') 
      // this.removeWellLoading = true;
      this.$store.dispatch('removeOneWell', {innerArray,outerArray})
      .then(() => {
        this.$store.commit('updateLocalUWISearch')
        
      })      

    },
    // add button is clicked dispatches an action
    // !!!
    // was (e)
    createDefaultProject: function(){
      // console.log('createdefaultproject function called', this.$store.state.createDefaultProject)
      //this.$store.dispatch('createDefaultProject')
    },



    ///////////////////////////////////////////////////////
    openVideo(){
      window.open("https://youtu.be/yQf-C8GMnso");
    },

    viewNoneOperatedWells(){
      this.$store.commit("viewNoneOperatedWells")
    },    


    changeView: function(){
      this.swapView = !this.swapView

    },
    getWells: function(e){
      this.searchLoading = true;
      localStorage.search = e.target.elements.fname.value

      let multiData = {fname: e.target.elements.fname.value}


      // GETS WELLS THAT WERE SEARCHED FOR THEN STORES IT IN LOCALSTORAGE
      axios.post(process.env.VUE_APP_EX_IP+'/api/postgis_2', multiData, {withCredentials: true}).then((data) => {   
        // FLASHMESSAGE IS IN MAIN AND IS HANDED DOWN TO ALL CHILDREN
        // WE EMIT EVENTS BACK TO TH MAIN.JS TO PROPAGATE DOWN {MSG: 'MESSAGE', TYPE: 'ERROR'}
        // if login error while retreiving data, sent to login screen
        if(data.data.errors){
          this.$emit('updateFlash', data.data.errors)
          this.$router.push({name: 'Login'})
        }
        this.searchLoading = false; // stop spinner
        this.wells = data.data

        // PERSISTENT DATA FOR SEARCH BOX OR MULTIDATA
        localStorage.localUWISearch = JSON.stringify(data.data)
        this.$store.dispatch('getMultipleUWIResults', data.data)
      }).catch(err => {console.log('error from post', err)})
    },
    pinDrops: function(){
      this.$router.push({name: 'Mapp', params: { pins: this.$store.getters.simpleMultipleUWIResults, zoom: 12}})
    },
  },
  updated() {
  },
  created() {
  },
  mounted(){
    if (localStorage.search){
      this.search = localStorage.search;
    }
  },
  beforeCreate(){
    if (!localStorage.userInfo){
      this.$router.push('/')
    }
    // axios.get(process.env.VUE_APP_EX_IP+'/api/validate',{withCredentials: true})
    // .then((response) => {  
    //   if(!response.data.isLoggedIn){
    //     this.$router.push({name: 'Login'})
    //   }
    // })
    // .catch((errors) => {
    //   if(errors.request){
    //     this.$router.push({name: 'Login'})
    //   }
    // })
  },
}
</script>

<style>
.button-container {
  /* max-width: 100%; */
  border: 2px solid #546e7a;
  position: relative;
  background-color: #f8f9fa;
  /* background-color: red; */
  padding: 10px;
  display: block;
  color: white;
}
.button-container-label {
  font-weight: bold;
  position: absolute;
  top: -10px;
  left: 20px;
  width: 150px; 
  background-color: #e5ebee;
  border: 2px solid #546e7a;
  color: #546e7a;
  text-align: center;
}
.filter-buttons{
  color: red;
  background-color: red;
  outline-width: 1px;
  display: nonee;
}
.global-btn{
  display: inline;
}
.form {
  max-width: 100%;
  grid-gap: 10px;
  display: grid;
  /* 4 COLUMNS THAT ARE ONE FRACTION */
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(10px, auto)
}
textarea {
  line-height: normal !important;
}
/* GRID-COLUMN IS WHERE THE ITEM IS LOCATED, LINE 1 THROUGH 3 FOR TEXT AREA AND LIGHT 3 THROUGH 5 FOR FORM BUTTONS */
.text_area{
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}
.form_buttons{
  grid-column: 3 / 5;
  grid-row: 1 / 2;
}

@media screen and (max-width: 760px){

textarea {
  line-height: normal !important;
}
.text_area{
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}
.form_buttons{
  grid-column: 3 / 5;
  grid-row: 1 / 2;
}
.global-btn{
  display: none; 
}
/* SMALL SCREEN SIZE **********************************************/
@media screen and (max-width: 500px){
  .text_area{
    grid-column: 1 / 5;
    grid-row: 2 / 3;
  }
  .form_buttons{

    grid-column: 1 / 5;
    grid-row: 1 / 2;
  }  
}


}

</style>