<template>
  <div> 
    <v-container>

      <v-layout wrap justify-space-around>

        <v-flex xs12>
            <h1 style="text-align: center" class="mt-5 blue-grey--text"><v-icon size="75" class="blue-grey--text text--darken-3">mdi-compasss</v-icon>Register @ W5th</h1>
        </v-flex>
        
        <v-flex xs12 md6 mt-3>

          <v-alert type="error" v-if=validators class='font-weight-medium' dense >    
              <div v-for="validator in validators" :key=validator.param>
                  {{validator.param}} : {{validator.msg}}
              </div>
          </v-alert>

          <v-alert :type=flashMessage.type v-if=flashMessage.msg class='font-weight-medium' dense>        
            <div>
              {{flashMessage.msg}}
            </div>
          </v-alert>

          <v-form @submit.prevent="login">    
            <v-text-field 
            v-model="email" 
            autocorrect="off" 
            autocapitalize="off" 
            spellcheck="false"  
            type="text" 
            placeholder="Email" 
            append-icon="mdi-email">
            </v-text-field>    

            <v-autocomplete
            v-model="company"
            :loading="loading"
            :items="items"
            :search-input.sync="search"
            cache-items
            placeholder="Company"
            hide-no-data
            hide-detailss
            labell="Company"
            clearable
            class="mb-2"
            hint="Start typing to choose your company">
            </v-autocomplete>   

            <!-- <v-text-field name="password"  type="password" placeholder="Password" append-icon="mdi-lock"></v-text-field>     -->
            <v-text-field v-model="password"  type="password" placeholder="Password" append-icon="mdi-lock"></v-text-field>    
            <v-text-field v-model="verification"  type="password" placeholder="Retype Password" append-icon="mdi-lock"></v-text-field>    
            <v-btn outlined tile class="blue-grey--text my-3" type="submit" value="Login"><span>REGISTER</span><v-icon small right>mdi-exit-to-app</v-icon></v-btn>               
          </v-form>

        </v-flex>                
      </v-layout>
    </v-container>      
  </div>
</template>

<script>
    // import router from 'vue-router'
    import axios from "axios"
    
    // axios.defaults.withCredentials = true;

    export default {    
        name: "Register",  
        data(){
          return{
            // AUTOSEARCH FORM
            loading: false,
            items: [],
            search: null,
            company: null,

            email : null,
            verification : null,
            password : null,

            validators: null,
          }
          
        }, 
        props:['flashMessage'],
        watch:{
          //!!! SELECTS THE COMPNAY FROM A LIST, LETS LOOK AT THIS CLOSER
          search (val) {
            val && val !== this.company && this.querySelections(val)
          }
        },
        methods: { 

          querySelections (v) {
            this.loading = true
            // Simulated ajax query
            setTimeout(() => {
              this.items = this.companyListData.filter(e => {
                return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
              })
              this.loading = false
            }, 500)
          },


          companyList: function(){
            console.log('here is the operator list')
            //GRABS A LIST OF ALL COMPANYS IN ARRAY FOR
            axios.post(process.env.VUE_APP_EX_IP + '/api/company_list')
            .then((response) => {
              // if(err)
              this.companyListData = response.data
              console.log('data of all companies in an array format', this.companyListData[3])
            }).catch((err) =>{ console.log(err)})
            // axios.post('')
          },

          login: function (e) { 
              e.preventDefault() 
              this.$emit('updateFlash', {})
              this.validators = null  
              //
              if(this.password == this.verification && this.password.length > 4){
                let login = () => {    
                  let data = {    
                    email: this.email,    
                    password: this.password,
                    company: this.company    
                  }    
                  axios.post(process.env.VUE_APP_EX_IP + '/api/register', data, {withCredentials: true})   
                    .then((response) => { 
                      if(response.data.errors){
                        this.$emit('updateFlash', response.data.errors[0])
                      } else {
                        this.$emit('updateFlash', {type: 'success', msg: 'Check your email and verify registration before trying to login'})
                        this.$emit('updateFlash', response.data.success[0])
                        this.$router.push({name: "Login"})
                      }

                    })    
                    .catch((errors) => {  
                        if (errors.response){
                            this.validators = errors.response.data.errors
                        }  else if ( errors.request){
                            this.validators = ([{msg:'Server cannot be reached', param:'Networking'}])
                        } else {
                            // nothing should ever get here
                            console.log('something very bad happend')
                        }
                    })    
                }
                login()   
              }else if (this.password == this.verification && this.password.length < 5){
                // else if(this.password == this.verification && this.verification.length < 5){
                  this.$emit('updateFlash', {type: 'error', msg: 'Password needs to be at least 5 characters long'})
              }else {
                  this.password = ''
                  this.verification = ''
                  this.$emit('updateFlash', {type: 'error', msg: 'Paswords did not match'})

              }

                  
          },
  
        },
        mounted(){
          this.companyList();
          console.log('im mounted')
        },
        beforeMount(){
        }    
    }
</script>