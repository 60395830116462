<template>
	<div class='mt-12'>
			<v-alert type='warning' class='font-weight-medium' dense >        
					<!-- <div v-for="validator in validators" :key=validator.param> -->
							"Please wait while we confirm your email"
					<!-- </div> -->
			</v-alert>
			
	</div>

</template>

<script>
	import axios from 'axios'
	// below sets axios with credentials on all axios calls
	// axios.defaults.withCredentials = true;
	
	export default {
		name: 'Confirmation',
		data () {
			return {
				//fromExpress: ''
			}
		},
		methods: {
			confirm: function (){
				console.log("test", this.$route.params)
				//!!!axios.get('/api/mytests')
				//axios.get('http://localhost:4000/api/mytests',{withCredentials: true})
				//!!!! remove credentials if not needed
				//!!! avoid adding reactive propertis to a vue instance or its root $data at runtime
				axios.get(process.env.VUE_APP_EX_IP+'/api/confirmation/'+this.$route.params.id,{withCredentials: true})
				// VUE_APP_EX_IP
				// axios.get('http://localhost:4000/api/mytests')
				//console.log('URL from mytest', URL)
				//axios.get(URL+'mytests')
				.then (response => {
					//console.log('I think we got a response', response)
					//this.$set(this, 'fromExpress', response.data.test)
					//console.log('fromExpress', this.fromExpress)
					this.$emit('updateFlash', {type: 'success', msg: 'You are registered and can now login'})
          this.$router.push({name: "Login"})
				})

			}
		},
		beforeMount (){
			this.confirm();
		},

	}
</script>