<template>
  <div>
    <v-container fluid v-if="verified">
      <v-card outlinedd class="my-2 d-print-none">
        <v-toolbar class="clip_board" densee flat color="blue-grey lighten-2" >   
          
          <h1 class="white--text">Projects</h1> 

          <v-spacer></v-spacer>
          <v-spacer></v-spacer> 
          <v-btn v-on:click="map()" tile class="mx-0">Map</v-btn>
          <v-btn v-on:click="showAll = !showAll"  tile  class="mx-1 d-none d-sm-flex" :color='showStyle()'><v-icon small color="blue-grey darken-3" >mdi-eye</v-icon> Show All </v-btn>
          <v-btn v-on:click="showAll = !showAll" :outlined='showAll' class="ma-2 d-flex d-sm-none" :class='showAll ? "v-btn--active" : ""' small :tile='showAll'  color="white lighten-3" icon>
            <!-- <v-icon v-on:click="showAll = !showAll" color="white darken-3" class="d-flex d-sm-none px-3">mdi-eye</v-icon> -->
            <v-icon color='white'>mdi-eye</v-icon>
          </v-btn>              
        
          <!-- <v-btn v-if="!swapView" v-on:click="editProject" class="mx-2 d-none d-sm-flex"><v-icon small color="blue-grey darken-3" >mdi-pencil</v-icon>Edit</v-btn> -->
          <v-btn  v-on:click="editProject" tile class="mx-0 d-none d-sm-flex">
              <v-icon v-if="!swapView" small color="blue-grey darken-3" >mdi-pencil</v-icon>
              <span v-if="!swapView"> Edit </span>
              <v-icon v-if="swapView" small color="blue-grey darken-3" >mdi-content-save</v-icon>
              <span  v-if="swapView"> Save </span>              
          </v-btn>

          <v-icon v-if="!swapView" v-on:click="editProject" color="white darken-3" class="d-flex d-sm-none ">mdi-pencil</v-icon> 
          <v-icon v-if="swapView" v-on:click="editProject" color="white darken-3" class="d-flex d-sm-none ">mdi-content-save</v-icon> 

        </v-toolbar>
      </v-card>
      <!-- <ProjectsView :showAll="showAll" ref="projectsview" v-if="!swapView"/> -->
      <ProjectsView :showAll="showAll" v-if="!swapView"/>
      <!-- show the project edit module if swapview is true -->
      <!-- <ProjectsEdit :showAll="showAll" ref="projectsedit" v-if="swapView" /> -->
      <!-- still need ref for save -->
      <ProjectsEdit :showAll="showAll" ref="projectsedit" v-if="swapView" />
    </v-container>     
  </div>
 
</template>


<script>

import axios from "axios"
import ProjectsEdit from '@/components/ProjectsEdit'
import ProjectsView from '@/components/ProjectsView'
//need to set this for axios.post
axios.defaults.withCredentials = true;

export default {
  components: {
    ProjectsEdit,
    ProjectsView
  },
  data(){
    return{
      showAll: false,
      // using the verified boolean to wait for the login verification before loading the dom elements, works o.k
      verified: false,
      swapView: false,
      // this can be used for the :rules in textarea
      //rules: [v => v.length <= 200 || 'Max 200 characters'],      
      type: [
        {
          id: 1,
          text: "Wireline",
          value: "Wireline"
        },
        {
          id: 2,
          text: "Fluidshot",
          value: "Fluidshot"
        },
        {
          id: 3,
          text: "Other",
          value: "Other"
        }
      ],
    }
  },
  methods: {
    map(){
      console.log('MAP pressed')
      // this.$router.push({name: 'Mapp', params: { from: 'projects'}})
      this.$router.push({name: 'Mapp', params: { pins: this.loadProjectData}})

    },
    map2(){
      //console.log('Map button pressed', this.loadProjectData)

      let multiData = ""

      this.loadProjectData.forEach( function (data){
        // console.log('data from for each', data, index)
        multiData = multiData + data.uwi + '\r'
      })
      multiData = { fname: multiData }

      axios.post(process.env.VUE_APP_EX_IP+'/api/postgis_2', multiData, {withCredentials: true}).then((data) => {   
        this.wells = data.data
        console.log('this wells data', this.wells)
        //dispatch an action
        // saves items in the store as an array of arrays 
        this.$store.dispatch('getMultipleUWIResults', data.data)
        if (data.data.authenticated === false){
          console.log('you are not authenticated please log in')
          this.notauthed = true
        }
      })
      .then(() => {
        this.$router.push({name: 'Mapp'})
      })
    },
    showStyle(){
      if (this.showAll){
        return 'green'
      }
      
    },
    // projectEdit(){
    //   // this refrences the child componenet , projectedit and allows the saveproject function to be call ed
    //   this.$refs.projectedit.saveProject()
    // },
    // dispatch an action
    addItemToDefaultProject(e, index,type){
      // console.log('e from type', e)
      this.$store.dispatch('addItemToDefaultProject', {index, e, type})
    },
    loadProject(){
      // console.log('load clicked')
      axios.get(process.env.VUE_APP_EX_IP+'/api/get_projects',{withCredentials: true})
      .then((data) => { 
        // console.log('load axios', data)

        // dispatch an action
        this.$store.dispatch('loadProjectData', data)
      })
    },
    editProject(){
      // console.log('edit project')
      // console.log('swapView variable', this.swapView)
      this.swapView = !this.swapView
      if(!this.swapView){
        this.$refs.projectsedit.saveProject()
      }
      
    },
  },
  computed:{
    loadProjectData(){
      // gives us access to createdefaultproject info from store
      return this.$store.state.loadProjectData
    }
  },

  beforeCreate(){
    // console.log('before create')
  },

  updated(){
  },
  created(){
    axios.get(process.env.VUE_APP_EX_IP+'/api/validate',{withCredentials: true})
    .then((response) => {  
      if(!response.data.isLoggedIn){
        this.$router.push({name: 'Login'})
      }
      this.verified = true
      
    })    
  },
  mounted(){
    this.loadProject()
    // console.log('el test',this.$el);
  }
  

}
</script>