import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import 'vuetify/dist/vuetify.min.css';
// import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify, {
  iconfont: 'md',
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // background: '#aaaaaa',
        //error: colors.green.darken2,
        // primary: '#9652ff', //purple
        primary: '#37474f', //purple
        success: '#009933', // green
        info: '#ffaa2c',
        //error: '#f83e70'
        error: '#ff0045'
        
      },
    },
    light: true,
  },
});
