<template>
  <div>
    <v-expansion-panels flatt accordionn multiple outlined>
      <!-- border-left: 6px solid green :style="classes" -->
      <v-expansion-panel v-for="(data, index) in loadProjectData" :key="index"  :id=index>
        <!-- <div v-if="showComplete || !data.complete" :style='data.complete ? completeStyle : ""'> -->
        <!-- example = border-leftt: 6px solid green; color: green;" -->
        <!-- <div v-if="showComplete || !data.complete || data.delete || !data.delete" :style='data.complete ? completeStyle : ""'> -->
        <div v-if="showAll || !data.complete" :style='completeStyle(data)'>  
        <!-- <div v-if="true" >     -->
          
          <v-expansion-panel-header >
            <v-layout class="px-3"  wrap >  
           
              <v-flex xs12 sm4 md4>
                <div class="caption grey--text">UWI</div>
                <div class="body-2 pt-2">{{data.uwi}}</div>
                <v-divider class="d-flex d-sm-none mb-3 mt-1"></v-divider>
              </v-flex>
            
              <v-flex xs12 sm4 md4 class="hidden-xs-onlyy">
                <div class="caption grey--text">LSD</div>  
                <div class="body-2 pt-2">{{data.surfloc}}</div>
                <v-divider class="d-flex d-sm-none mb-3 mt-1"></v-divider>
              </v-flex>

              <v-flex xs8 sm2 md2>
                <div caption class="caption grey--text">Type of Work</div>  
                <v-select :items="type"   class='mystyle' item-text="text"  :placeholder="loadProjectData[index].type || 'Other'" item-value="value" outlined flat @click.native.stop v-on:change="editItemToDefaultProject($event, index, 'type')" dense solo label="select">
                </v-select>   
              </v-flex>
              <v-flex xs4 sm2 md2 class=" pl-3">
                <div caption class="caption grey--text ">Action</div>  
                <!-- <div>delete: {{data.delete}}</div>
                <div>complete: {{data.complete}}</div> -->
                <v-btn class="pt-4" title="Delete Item" x-small text icon color="red"  @click.native.stop v-on:click="editItemToDefaultProject($event, index, 'delete', data)"><v-icon size="20">mdi-minus-circle</v-icon></v-btn>
                <v-btn class="pt-4" title="Complete Item"  x-small text icon color="green" @click.native.stop v-on:click="editItemToDefaultProject($event, index, 'complete', data)"><v-icon size="20">mdi-check-circle</v-icon></v-btn>
                <!-- :disabled="data.delete" -->
              </v-flex>            
            </v-layout>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-flex xs12 sm6 md6 class="mt-n1">
              <h4 class="blue-grey--text">Description / Notes</h4>
              <!-- pass $event and index into function, event = e -->
              <v-textarea solo light counter :rules="[v => v.length <= 200 || 'Max 200 characters']" :value="loadProjectData[index].description || ''" flat outlined v-on:change="editItemToDefaultProject($event,index, 'description')" ></v-textarea>
            </v-flex>
          </v-expansion-panel-content> 
        </div>
        
      </v-expansion-panel> 
    </v-expansion-panels>  
  </div>
</template>

<script>

import axios from 'axios'
// import "core-js/stable";
// import "regenerator-runtime/runtime";

export default {
  props: ['showAll'],
  data(){
    return{
      showComplete: false,
      delete: true,
      flat: true,
      inset: true,
      type: [
        {
          id: 1,
          text: "Wireline",
          value: "Wireline"
        },
        {
          id: 2,
          text: "Fluidshot",
          value: "Fluidshot"
        },
        {
          id: 3,
          text: "Other",
          value: "Other"
        }
      ],
    }
  },
  methods: {


    // index index, type description or type or delete or completed, e is the contend, 'wireline',  'new description text'
    // was (e, index,type, data)
    editItemToDefaultProject(e, index,type){
      
      //console.log('data from type', data)
      // console.log('this from edit', this)
      //!!!
      // let element = document.getElementById(index);
      // if(type === 'delete'){
      //   console.log('deleeeeeeeeeeeeeeeeeeeeeeeeeee' +type)
      //   console.log(' data.complete ' +data.complete)
      //   //var element = document.getElementById(index);
      //   element.classList.toggle("red--text")        
      // } else if (type === 'complete') {
      //   console.log(' compleeteeeeeeeeeeeeeeeeeeee')
      //   //var element = document.getElementById(index);
      //   element.classList.toggle("green--text")         
      // }



      // if (type === 'complete') {
      //   console.log(' complete')
      //   //var element = document.getElementById(index);
      //   element.classList.toggle("blue--text")         
      // } else if (type === 'delete'){
      //   console.log('delete' +type)
      //   // console.log(' data.complete ' +data.complete)
      //   //var element = document.getElementById(index);
      //   element.classList.toggle("red--text")        
      // }

      
      // take 3 items with me, dispatch an action, index event and type
      // dispatch an action
      this.$store.dispatch('editItemToDefaultProject', {index, e, type})
    },
    saveProject(){
      let list = []
      console.log('list', list)

        this.loadProjectData.forEach( (data) => {
          if(data.modified){
            console.log('was data modified', data)
            list.push({name: data.name, well_id: data.well_id, description: data.description, type: data.type, id: data.id, delete: data.delete, complete: data.complete})
            return
          } 
          // console.log('save project', data)
          
        })
        //console.log('list after push', list)
        if(list.length){
          console.log('length of list', list.length)
          axios.post(process.env.VUE_APP_EX_IP+'/api/edit_project', list, {withCredentials: true}).then((data) => {
            console.log('success', data)

            // async function test3(){console.log('this is async jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj', data)}
            // test3();
            // commit a mutation
            // var testt = new Promise (function(resolve, reject) {
            //   console.log('testing pause function')
            //     // setTimeout(() => {
            //     //     console.log('after pause')
            //     // }, 2000)
            //     resolve ('yes')
            // }).then(() => {
            //   console.log('then working')
            // })
            // testt
            //pause();
            // this.$store.commit('resetLoadProjectDataModified')
            //this.$store.commit('loadProjectData')
          })
          .then(() =>{
            axios.get(process.env.VUE_APP_EX_IP+'/api/get_projects',{withCredentials: true})
            .then((data) => { 
              console.log('load axios', data)

              // dispatch an action, load remotely retreived data, we will need to 
              // store this in vuex to speed up
              this.$store.dispatch('loadProjectData', data)
            })
          })
          // .then(()=>{
          //   console.log('this is after receiving data back')
          //   this.$store.commit('resetLoadProjectDataModified', list)
          //   })
          .catch(err => {
            console.log(err)
          })
        }
    },
    completeStyle(data) {
      //return "color: orange;"
      if(data.complete && !data.delete){
        return  "border-leftt: 6px solid green; color: green;";
      } else if(data.delete){
        return "color: red;"
      }
    },        
  },
  computed:{
    // completeStyle() {
    //   //return "color: orange;"
    //   if(data.complete){
    //     return  "border-leftt: 6px solid green; color: orange;";
    //   } 
    // },    
    loadProjectData(){
      console.log('this is the stupid object')
      console.log(this.$store.state.loadProjectData)
      // gives us access to createdefaultproject info from store
      return this.$store.state.loadProjectData
    }
  },  
}
</script>


<style scoped>
.mystyle {
  color: green;
  }
</style>