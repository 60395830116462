<template>
  <div>
    <!-- <v-container fluid> -->
      
      <div v-for="(data, indexx) in loadProjectData" :key="indexx">
        <!-- !data.complete || -->
        <v-card v-if="showAll || !data.complete" class="my-2" :style='data.complete ? completeStyle : ""' flat outlined >
          <!-- <div v-if="data.completee"> -->
          <v-card-title class="subtitle-1 font-weight-boldd px-7" >
            <v-layout wrap row >

              <v-flex xs12 sm8 >
                <header class="caption grey--text">Location</header>
                <span>{{data.uwi}}</span>
                <span class="hidden-xs-only grey--text"> ({{data.surfloc}})</span>
              </v-flex>

              <!-- needed 2 flex items because this would not honour a br -->
              <v-flex xs12 class="d-flex d-sm-none">
                <!-- <v-btn block></v-btn> -->
                <header class="caption grey--text">Surface<br></header><br>
              </v-flex>
            <v-flex xs12 class="d-flex d-sm-none">
                <span>{{data.surfloc}}</span>
              </v-flex>

              <v-flex xs12 sm4>
                <header class="caption grey--text">Type of Work</header>
                <span>{{data.type}} </span>
                <span v-if='!data.type'>Other</span>
              </v-flex>            
            </v-layout>   
          </v-card-title>
          <v-divider></v-divider>
          <v-list>
            <v-list-item>

              <!-- <v-list-item-content block class="caption"> description</v-list-item-content> -->

              <v-list-item-content class="caption"> 
                <header class="caption grey--text pb-1"  >Description</header>
                <span class="mb-6">{{data.description}}</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- </div> -->
        </v-card>
      </div>
    <!-- </v-container> -->

  </div>
</template>

<script>

import axios from 'axios'

export default {
  props: ['showAll'],
  data(){
    return{
      showComplete: false

    }
  },
  methods: {

  },
  computed:{
    completeStyle() {
      return  "border-leftt: 6px solid green; color: green; ";
        //return {red: true}
    },    
    loadProjectData(){
      // gives us access to createdefaultproject info from store
      return this.$store.state.loadProjectData
    }    
  },
  beforeCreate(){
    //axios.get(process.env.VUE_APP_EX_IP+'/api/validate',{withCredentials: true})
    axios.get(process.env.VUE_APP_EX_IP+'/api/get_projects',{withCredentials: true})
    // was (response)
    .then( () => {
      // console.log('responce', response)

    })

    
  }
}
</script>