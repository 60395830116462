<template >
  <!-- remove navbar when printing -->
  <nav class="d-print-none">
    <!-- <v-toolbar color="blue-grey darken-3" height="54px" flat app> -->
    <v-app-bar  color="grey lighten-4" height="54px" app flat>
      <!-- this is the hamburger -->
      <v-app-bar-nav-icon class="grey--text text--lighten-1" @click="drawerClick"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title class="center">
        <v-icon large color="blue-grey darken-3" >mdi-compass</v-icon>
        <span class="font-weight-bold blue-grey--text darken-4">West</span>
        <!-- <span class="font-weight-light white--text"> of the </span> -->
        <span class="font-italic blue-grey--text darken-4"> of the </span>
        <span class="font-weight-bold blue-grey--text darken-4">5th</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- LOGGED IN -->
      <v-btn @click='logout()' v-if='userInfo' small outlined color="blue-grey darken-1">
        <div >
          <span>Sign Out</span>
          <v-icon small right>mdi-exit-to-app</v-icon>
        </div>
      </v-btn>
      <!-- NOT LOGGED IN -->
      <v-btn @click='login()' v-if='!userInfo' small outlined color="blue-grey darken-1">
        <div >
          <span>Log In</span>
          <v-icon small right>mdi-exit-to-app</v-icon>
        </div>
      </v-btn>

    </v-app-bar>

    <v-navigation-drawer v-model="drawer" overlay-opacity=0.0 app floating  color='blue-grey darken-3'>    
      <v-list > 
        <v-subheader class="white--text title">NAVIGATION</v-subheader>
        <v-list-item-group dark color=""  class="subtitle-1 grey--text text--lighten-2" >
          <!-- :disabled=isLoggedIn -->
          <v-list-item v-for="link in links"  :key="link.text" router :to="link.route">
            <!-- <v-list-item-icon> -->
              <v-icon left>
                mdi-{{link.icon}}
              </v-icon>
            <!-- </v-list-item-icon> -->
            <v-list-item-content>
              {{link.text}}
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>

</template>

<script>

import axios from 'axios'

export default {
  data(){
    return{
      drawer: false,
      links: [
        {icon: 'view-dashboard', text: 'Dashboard', route: '/dashboard'},
        {icon: 'bookmark', text: 'Bookmarked', route: '/bookmarked'},
        // {icon: 'folder', text: 'My Projects', route: '/projects', disabled: true},
        // {icon: 'account', text: 'About', route: '/about'},
        {icon: 'map', text: 'Map', route: '/mapp'},
        {icon: 'map-search', text: 'Search', route: '/multisearch'},
        // {icon: 'login', text: 'Login', route: '/login'},
      ]
    }
  },
  methods:{
    drawerClick: function (){
      this.drawer= !this.drawer

    },
    logout: function(){
      axios.get(process.env.VUE_APP_EX_IP+'/api/logout',{withCredentials: true})
      // was (response)
      .then(() =>{
        localStorage.clear();
        this.$store.commit('updateUserInfo', null)
        this.$router.push({name: 'Login'})
      })
    },
    login: function(){
      if(this.$route.name !== 'Login'){this.$router.push({name: 'Login'})}
    },
  },
  computed: {
      userInfo(){
      // // returns user info thats passed into the store from the login screen
          return this.$store.state.updateUserInfo;
      }
  },   

  beforeMount(){
  },
  mounted(){
  },




}
</script>