<template>
  <div>

    <v-container v-if="this.userInfo" class="my-0" :fluid="false" >  

      <v-card
          max-width="344"
          outlined
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="blue-grey--text overline mb-4">User Information</div>
            <v-list-item-title class="blue-grey--text headline mb-1">Email</v-list-item-title>
            <v-list-item-subtitle class="blue-grey--text">{{this.userInfo.user}}</v-list-item-subtitle>
            <v-list-item-title class="blue-grey--text headline mb-1">Company</v-list-item-title>
            <v-list-item-subtitle class="blue-grey--text" v-if='!editVisible'>{{this.userInfo.company}}</v-list-item-subtitle>        
            <v-form v-if="editVisible" class='formm' @submit.prevent="update">    
              <v-autocomplete
              v-model="company"
              :items="items"
              :search-input.sync="search"
              cache-items
              :placeholder = userInfo.company
              hide-no-data
              hide-detailss
              labell="Company"
              clearable
              class="mb-2"
              hint="Start typing to choose your company">
              </v-autocomplete>   
            </v-form>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions>
          <v-btn outlined tile class="blue-grey--text my-3 mx-2" v-if=!editVisible v-on:click='edit'>Edit</v-btn>
          <v-btn outlined tile class="blue-grey--text my-3 mx-2" type="submit" v-if=editVisible v-on:click='update'>Update</v-btn>
        </v-card-actions>
      </v-card>

    </v-container>
  </div>  
</template>

<script>

import axios from "axios"

export default {
  data(){
    return{
      pushedMe: {one: 'one', two: 'two'},
      editVisible: false,

      //returned results from post requrest
      companyListData: null,

      // company that is bound to the form
      company: null,
      //filtered items while typing
      items: [],
      search: null,

    }
  },
  methods: {

    // could take in an argument 'e'
    update(e) {
      // console.log('e', this.company, this.companyListData.includes(this.company))
      axios.post(process.env.VUE_APP_EX_IP + '/api/user_update', {company: this.company}, {withCredentials: true})   
        .then((response) => { 
          if(response.data.errors){

            console.log('some errors were detected within axio', response.data.errors)
            // this.$emit('updateFlash', response.data.errors[0])
            // error returned will then remove user infor
            localStorage.removeItem('userInfo')
            this.$router.push({name: 'Login'})
          } else {
            this.$store.commit('updateUserInfo', response.data)            
            localStorage.userInfo = JSON.stringify(response.data)
          }
        })    
        .catch((errors) => {  
          if (errors.response){
              this.validators = errors.response.data.errors
          }  else if ( errors.request){
              this.validators = ([{msg:'Server cannot be reached', param:'Networking'}])
          } else {
              // nothing should ever get here
              console.log('something very bad happend', errors)
          }
      }) 
      this.editVisible = false
    },

    querySelections (v) {
      // this.loading = true
      setTimeout(() => {
        this.items = this.companyListData.filter(e => {
          return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
        })
        // this.loading = false
      }, 500)
    },

    companyList: function(){
      //GRABS A LIST OF ALL COMPANYS IN ARRAY FOR
      axios.post(process.env.VUE_APP_EX_IP + '/api/company_list')
      .then((response) => {
        this.companyListData = response.data
      }).catch((err) =>{ console.log(err)})
    },


    edit: function(){
      this.editVisible = true
      this.companyList()
    },
  },
  // this is for vuex accessing state from store.js, imported in mian.js
  watch: {
    search (val) {
      val && val !== this.company && this.querySelections(val)
    }
  },
  computed: {

    userInfo() {
      // ************************************************************************************************
      // console.log('user info from dashboard ', this.$store.state.updateUserInfo)
      // // returns user info thats passed into the store from the login screen

        return this.$store.state.updateUserInfo
    }
  },
  beforeCreate(){
    if (!localStorage.userInfo){
      this.$router.push('/')
    } else { 
    }   
  },
  mounted(){
  }  
  
}

</script>

<style>
  .form{
    width: 1400px;
  }

</style>