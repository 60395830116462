import 'core-js/stable';
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';

import { store } from './store/store'
import './registerServiceWorker'

Vue.config.productionTip = false




// err: complete error trace, contains the message and error stack
// vm: Vue component/instance in which error is occurred
// info: Vue specific error information such as lifecycle hooks, events etc.
// was (err,  vm, info)
Vue.config.errorHandler = (err) => {
  // err: error trace
  console.log('error from mainjs ', err)
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.
  
  // TODO: Perform any custom logic or log to server

};



new Vue({
  store: store,
  router,
  vuetify,
  beforeCreate() { 
    this.$store.commit('initialiseStore')
    // this.$store.dispatch('initialiseStore')
  },
  render: h => h(App)
}).$mount('#app')


// can create global mixins in here