<template>
  <!-- test title does nothing -->
  <div class="about" title="About W5th" >
    

    <!-- add fluid to container class for large width -->
    <v-container class="my-5">
      <h2 class="subheading blue-grey--text">May 2020</h2>
      <p>Some tips. If an item in the project has been completed it will be marked green. Save button is 
        self-explanatory, it saves changes to the project, including deletes and completes.   Searching 
        for wells can be multiple or single searchs.  for instance you can search for 01-02-040-21W5 and 01-03-040-21W5 
        at the same time.  They just need to be seperated by a line break.  You can also search for something like 040-21W5 
        and this will bring up all wells in that area.  Search strings must be at least 5 characters long. 
      </p>      
      <h2 class="subheading blue-grey--text">April 2020</h2>
      <p>Fixing some minor bugs with the responsive design.  Still have a ways to go here but basic functionality is available between 
        desktop and mobile sites.
      </p>       
      <h2 class="subheading blue-grey--text">March 2020</h2>
      <p>Minor changes to the 'My Projects' section.  now able to hide/show completed items.  We've also updated the database so in the near 
        future we can implement more refined search options.  This took longer than expected but is mostly done now so progres on the front end
        should begin speeding up.
      </p>      
      <h2 class="subheading blue-grey--text">Feb 2020</h2>
      <p>The purpose of this site is to allow you to find, track and map the 
        wellsites you need across Alberta Canada.  We are currently in Beta testing and will be adding 
        more features in the near future.  Please send any feed back you have to
        admin@w5th.ca
      </p>
    </v-container>
  </div>
</template>


<script>

import axios from "axios"

export default {
  data(){
    return{

    }
  },
  beforeCreate(){
    // axios.get(process.env.VUE_APP_EX_IP+'/api/validate',{withCredentials: true})
    // .then((response) => {  
    //   if(!response.data.isLoggedIn){
    //     this.$router.push({name: 'Login'})
    //   }
    // })
    // .catch((errors) => {
    //   if(errors.request){
    //     this.$router.push({name: 'Login'})
    //   }
    //    //console.log(errors.request)
    // })
  },  
}
</script>