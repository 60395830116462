// import { forEach } from 'core-js/fn/array';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// CALLED FROM INITIALISE STATE 
function noneOperatedWellCount(state){
  state.getMultipleUWIResults.forEach( item => {
    item.foundarray.forEach( item => {
      if(!item.operator){
        state.noneOperatedWellCount += 1 
      }
    })
  })
}

export const store = new Vuex.Store({
  // STORES BASIC STATE OF STORE ITEM
  state: {

    saveList: [],
    noneOperatedWellCount: null,
    getMultipleUWIResults: [],
    loadProjectData: null,
    updateUserInfo: {name: 'place holder', company: 'place holder'},
    viewNoneOperatedWells: false,
    //createDefaultProject: [],
  },

  // GETTERS MANIPULATE DATA THAT IS IN THE STATE
  // instead of a simple array for bookmarks, maybe we should have the full array
  getters:{
    // USED IN MULTISEARCH.VUE PUSH THIS OBJECT TO MAP FOR PIN DROPS
    simpleMultipleUWIResults: state => {
      let simpleArray = []
      state.getMultipleUWIResults.forEach( (data) => {
        data.foundarray.forEach((data) => {
          simpleArray.push(data)
        })
      })
      return(simpleArray)

    }
  },

  // MUTATIONS ARE USED TO MODIFY DATA IN THE STORE
  // YOU COMMIT A MUTATION
  // this.$store.commit("viewNoneOperatedWells")
  mutations: {
    // REMOVE A SINGLE WELL FROM BOOKMARKS ARRAY
    simpleArrayRemove: (state, payload) => {
      state.saveList.splice(payload, 1)
    },

    // SAVE LIST, SHOULD MAKE THIS ASYNCHRONOUS
    saveList: (state, payload) => {
      
      // STATE IS THE GET MULTIPLE UWI RESULTS ARRAY
      
      let simpleArray = []
      if(state.viewNoneOperatedWells){
        state.getMultipleUWIResults.forEach( (data) => {
          
          data.foundarray.forEach((data) => {
            simpleArray.push(data)
          })
        })
      } else {
        state.getMultipleUWIResults.forEach( (data) => {
          
          data.foundarray.forEach((data) => {
            if(data.operator){
              simpleArray.push(data)
              // localStorage.setItem({})
            }
            
          })
        })

      }
      if(payload == 'overwrite'){
        state.saveList = simpleArray
        localStorage.setItem('saveList', JSON.stringify(state.saveList))
        // localStorage.setItem('localUWISearch', JSON.stringify(state.getMultipleUWIResults))
        // console.log('yes overwrite')
      } else {
        // FROM BOOKMARKED  
        state.saveList = state.saveList.concat(simpleArray)
        localStorage.setItem('saveList', JSON.stringify(state.saveList))
      }



    },

    // filteredList: (state) => {
    // },

    updateLocalUWISearch: (state) =>{
      localStorage.setItem('localUWISearch', JSON.stringify(state.getMultipleUWIResults))
    },


    // LETS SET EVERYTGHING UP BEFORE FIRST LOAD
    initialiseStore: (state) =>{
      if(localStorage.getItem('localUWISearch')){
        state.getMultipleUWIResults = JSON.parse(localStorage.getItem('localUWISearch'))
        noneOperatedWellCount(state)
      }
      state.updateUserInfo = JSON.parse(localStorage.getItem('userInfo'))
    },

    updateUserInfo: (state, payload) => {      
      state.updateUserInfo = payload
    },

    // FROM MULTISEARCH ARRAY OF ARRAYS
    getMultipleUWIResults: (state, payload) => {
      state.noneOperatedWellCount = 0;
      state.getMultipleUWIResults = payload
      payload.forEach( item => {
        item.foundarray.forEach( item => {
          if(!item.operator){
            state.noneOperatedWellCount += 1 
          }
        })
      })
    },

    removeOneWell: (state, payload) =>{
      state.getMultipleUWIResults[payload.outerArray].foundarray.splice(payload.innerArray, 1)
    },

    keepOneWell: (state,payload) => {
      state.getMultipleUWIResults[payload.outerArray].foundarray = state.getMultipleUWIResults[payload.outerArray].foundarray.filter((item) => {
        return item.id === payload.innerArray
      })
    },

    modifyItemToDefaultProject: (state, payload) => {
      state.getMultipleUWIResults[payload.outerIndex].foundarray[payload.innerIndex][payload.type] = payload.e
    },

    editItemToDefaultProject: (state, payload) => {
      state.loadProjectData[payload.index]['modified'] = true
      // Delete button login e = delete()  type = delete
      if (payload.type === 'delete'){
        state.loadProjectData[payload.index].delete = !state.loadProjectData[payload.index].delete
      } else if (payload.type === 'complete') {
        state.loadProjectData[payload.index].complete = !state.loadProjectData[payload.index].complete
      } else {
        state.loadProjectData[payload.index][payload.type] = payload.e
      }
    },
    loadProjectData: (state, payload) => {
      // wht is payload or payload.data
      state.loadProjectData = payload.data

    },
    // was state,payload
    viewNoneOperatedWells: (state) => {
      state.viewNoneOperatedWells = !state.viewNoneOperatedWells
    }

 
  },
  // ACTIONS ARE USED FOR ASYNC ITEMS
  // from vue file this.$store.dispatch('reducePrice', amount)
  // should always use actions then mutations
  // YOU DISPATCH AN ACTION
  actions: {
    simpleArrayRemove: (context, payload) => {
      context.commit('simpleArrayRemove', payload)
    },
    saveList: (context, payload) => {
      context.commit('saveList', payload)
    },

    // FROM MULTISEARCH ARRAY OF ARRAYS
    getMultipleUWIResults: (context, payload) => {

      context.commit('getMultipleUWIResults', payload)
    },
    removeOneWell: function(context, payload) {
      // using this for good habbits
      // PAYLOAD IS THE INNER AND OUTER ARRAY ID
      context.commit('removeOneWell', payload)
    },
    keepOneWell: (context, payload) => {
      context.commit('keepOneWell', payload)
    },

    modifyItemToDefaultProject: (context, payload) => {
      //commit a mutation
      context.commit('modifyItemToDefaultProject', payload)
    },
    loadProjectData: (context, payload) => {
      // commit a mutation
      context.commit('loadProjectData', payload)
    },
    editItemToDefaultProject: (context, payload) => {
      //commit a mutation
      context.commit('editItemToDefaultProject', payload)
    },

  
  },
})