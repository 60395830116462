<template>
    <div id="main_div"> 
       
        <v-container>

            <v-layout wrap justify-space-around>

                <v-flex xs12>
                    <h1 style="text-align: center" class="mt-5 blue-grey--text"><v-icon size="75" class="blue-grey--text text--darken-3">mdi-compasss</v-icon>Login @ W5th</h1>
                </v-flex>

                <v-flex xs12 md6 mt-3>
                    <!-- <v-alert type="error" v-if=validators  elevation="1"  dense colored-border border="left" > -->
                    <v-alert type="error" v-if=validators class='font-weight-medium' dense >    
                        <div v-for="validator in validators" :key=validator.param>
                            {{validator.param}} : {{validator.msg}}
                        </div>
                    </v-alert>

                    <!-- <v-alert type="error" v-if=flashMessage.msg class='font-weight-medium' dense outlined >     -->
                    <v-alert :type=flashMessage.type v-if=flashMessage.msg class='font-weight-medium' dense>        
                        <!-- <div v-for="validator in validators" :key=validator.param> -->
                            {{flashMessage.msg}}
                        <!-- </div> -->
                    </v-alert>

                    <v-form @submit.prevent="login">    
                        <v-text-field 
                        name="email"  
                        type="text" 
                        placeholder="Email" 
                        append-icon="mdi-email"
                        autocorrect="off" 
                        autocapitalize="off" 
                        spellcheck="false">
                        </v-text-field>    

                        <v-text-field name="password"  type="password" placeholder="Password" append-icon="mdi-lock"></v-text-field>    
                        <v-btn outlined tile class="blue-grey--text my-3" type="submit" value="Login"><span>SIGN IN</span><v-icon small right>mdi-exit-to-app</v-icon></v-btn>               

                    </v-form>
                    <v-btn text v-on:click=register>register</v-btn>
                    <v-btn text v-on:click=reset>Reset Password</v-btn>


                </v-flex>                
            </v-layout>
        </v-container>      
    </div>
</template>

<script>
    // import router from 'vue-router'
    import axios from "axios"
    
    
    // axios.defaults.withCredentials = true;

    export default {  
 
        name: "Login",  
        data(){
          return{
            // validators gets populated from the api
            validators: null,
          }
          
        }, 

        // passed down from app.vue
        props:['flashMessage'],
        components: {
        }, 
        methods: {   
            reset: function (){
                this.$router.push({name: "Reset"})
            },
            register: function (){
                this.$router.push({name: "Register"})
            },
            login: function (e) {   

                e.preventDefault() 
                this.$emit('updateFlash', {})
                this.validators = null  
                
                let email = e.target.elements.email.value
                let password = e.target.elements.password.value
                let login = () => {    
                    let data = {    
                        email: email,    
                        password: password    
                    }   
                    
                    // VUE_APP_EX_IP = 'http://localhost:4000'

                    axios.post(process.env.VUE_APP_EX_IP + '/api/login', data, {withCredentials: true})
                    // axios.post('http://localhost:4000' + '/api/login', data, {withCredentials: true})
                    .then((response) => {
                        localStorage.userInfo = JSON.stringify(response.data)
                        this.$store.commit('updateUserInfo', response.data)
                        this.$router.push({name: "Multisearch"})
                    })    
                    .catch((errors) => {  
                        if (errors.response){

                            this.validators = errors.response.data.errors
                        }  else if ( errors.request){
                            // this intercepts error if server cannot be reached and adds this array
                            // to the validators variable
                            this.validators = ([{msg:'Server cannot be reached', param:'Networking'}])
                        } else {
                            // nothing should ever get here
                            console.log('something very bad happend')
                        }
                    })    
                }    
                login()    
            },
    
        },
     
        beforeCreate(){
            if(localStorage.userInfo){
                this.$router.push({name: "Multisearch"})
            }
        },
        mounted(){
        },
        beforeMount(){
           
        },
        beforeDestroy(){
            // before it destroys the login session clear the flash message
            this.$emit('updateFlash', {})

        }
            
    }
</script>

<style>
#main_div
{
    /* background-color: red; */
}
</style>