<template>
    <div> 
 
      <v-container>

        <v-layout wrap justify-space-around>

          <v-flex xs12>
              <h1 style="text-align: center" class="mt-5 blue-grey--text"><v-icon size="75" class="blue-grey--text text--darken-3">mdi-compasss</v-icon>Enter a new password</h1>
          </v-flex>

          <v-flex xs12 md6 mt-3>

            <v-alert :type=flashMessage.type v-if=flashMessage.msg class='font-weight-medium' dense>        
              <div>
                {{flashMessage.msg}}
              </div>
            </v-alert>

              <v-form @submit.prevent="login">    
                <v-text-field v-model="password"  type="password" placeholder="Password" append-icon="mdi-lock"></v-text-field> 
                <v-text-field v-model="verification"  type="password" placeholder="Retype password" append-icon="mdi-lock"></v-text-field>   
                <!-- <v-text-field name="password"  type="password" placeholder="Password" append-icon="mdi-lock"></v-text-field>     -->
                <v-btn outlined tile class="blue-grey--text my-3" type="submit" value="Login"><span>Submit</span><v-icon small right>mdi-exit-to-app</v-icon></v-btn>               
              </v-form>
            </v-flex>                
        </v-layout>
      </v-container>      
    </div>
</template>

<script>
    // import router from 'vue-router'
    import axios from "axios"
    export default {    
        name: "Reset",  
        data(){
          return{
            data: null,
            email: null,

            password: null,
            verification: null,

            validators: null,
          }
        }, 
        props:['flashMessage'],
        methods: { 

          login: function (e) { 
            e.preventDefault()
            this.validators = null  
            this.$emit('updateFlash', {})

            // let test = 'yes'
            if(this.password == this.verification && this.verification.length > 4){
              let login = () => {   

                axios.post(process.env.VUE_APP_EX_IP + '/api/receive_reset/' + this.$route.params.token + '/' + this.$route.params.email, {password: this.password}, {withCredentials: false})   
                .then((data) => { 
                  this.$emit('updateFlash', data.data.response[0])
                  // this.$emit('updateFlash', {type: 'success', msg: 'Password has been reset, please login'})
                  this.$router.push({name: "Login"})
                })    
                .catch((errors) => {  
                  if (errors.response){
                      this.validators = errors.response.data.errors
                  }  else if ( errors.request){
                      this.validators = ([{msg:'Server cannot be reached', param:'Networking'}])
                  } else {
                      console.log('something very bad happend')
                  }
                })    
              }    
              login()  
            } else if(this.password == this.verification && this.verification.length < 5){
              this.$emit('updateFlash', {type: 'error', msg: 'Password needs to be at least 5 characters long'})
            } else {
              this.password = ''
              this.verification = ''
              this.$emit('updateFlash', {type: 'error', msg: 'Paswords did not match'})
            }
          },
        },
        mounted(){
        },
        beforeMount(){
        },
        beforeDestroy(){
        }            
    }
</script>